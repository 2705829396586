<template>
    <div>
        <li class="user-menu--item">
            <span class="user-link pointer" @click="!$store.state.edit ? $emit('section', { 'section': 'settings', 'title': 'Настройки' }) : ''">Настройки</span>
        </li>
        <li class="user-menu--item">
            <span class="user-link pointer" @click="!$store.state.edit ? Logout() : ''">Выйти
            </span>
        </li>
    </div>
</template>


<script>
export default {
    name: "UserMenu",
    data() {
        return {
        }
    },
    methods: {
        Logout() {
            const settings = {
                type: "logout",
                title: "Вы действительно хотите выйти?",
                ok: "Нет",
                cancel: "Да",
            }
            this.modalToggle(settings)
        }
    },
    mounted() {
        this.emitter.on("modal-cancel-logout", (val) => {
            if (val) {
                this.$store.state.myId = null
                this.axios.defaults.headers.common["Authorization"] = null
                this.$cookies.remove("token")
                this.$router.push("/")
                this.$store.state.modal.toggle = false
                this.$store.state.timestamp = 0
            }
        })
        this.emitter.on("modal-ok-logout", (val) => {
            if (val) this.$store.state.modal.toggle = false
        })
    }
}
</script>