<template>
    <div v-cloak v-show="$store.state.user.userId" class="base menu-wrapper">
        <ul class="menu" v-if="!$store.state.mobile">
            <li class="menu-item" :class="{ 'menu-item-active': $route.path == `/profile/id${myId}` }">
                <router-link class="menu-link pointer" :to="!$store.state.edit ? `/profile/id${myId}` : ''">
                    <i class="fa-solid fa-house"></i>
                    <span>Моя страница</span>
                </router-link>
            </li>
            <li class="menu-item" :class="{ 'menu-item-active': $route.name == 'Dialogs' }">
                <router-link class="menu-link pointer" :to="!$store.state.edit ? '/dialogs' : ''">
                    <i class="fa-solid fa-comments"></i>
                    <span>Диалоги</span>
                    <sup class="nav-msg-not" v-show="messagesCount > 0">{{
                        messagesCount
                    }}</sup></router-link>
            </li>
            <!--<li class="menu-item" id="nav-users">
                <router-link class="menu-link pointer" :class="{ 'menu-link-active': $route.name == 'Friends' }"
                    :to="!$store.state.edit ? '/freind' : ''">
                    <i class="fa-solid fa-calendar-days"></i>
                    <span>Мероприятия</span></router-link>
            </li>
            -->
            <li class="menu-item" :class="{ 'menu-item-active': $route.name == 'Feed' }">
                <router-link class="menu-link pointer" :to="!$store.state.edit ? '/feed' : ''">
                    <i class="fa-solid fa-newspaper"></i>
                    <span>Новости</span></router-link>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "Menu",
    components: {
    },
    data() {
        return {
            myId: 0
        }
    },
    mounted() {
        this.emitter.on("loaded", () => {
            if (!this.myId) this.myId = this.$store.state.user.userId
        })

    },
}
</script>