import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      timestamp: 0,
      token: '',
      user: {},
      myId: null,
      mobile: false,
      page: null,
      modal: {
        toggle: false,
        settings: {
          id: '',
          type: '',
          title: '',
          body: '',
          photo: '',
          ok: '',
          cancel: '',
        },
        temp: [],
      },
      edit: false,
      loaded: {
        avatars: false,
        content: false,
        preloader: false,
      },
      route: {
        meta: {
          name: ''
        }
      },
    }
  },
  getters() {},
  mutations() {},
  actions() {},
  modules() {},
})

export default store
