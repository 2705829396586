<template>
  <img class="min-photo rounded" v-bind:class="{
    userOnline: online(lastVisit),
  }" :src="photo" :data-id="userId" :onerror="imageErrorAvatar" alt="user_avatar" />
</template>

<script>
import {
  online
} from '@/../public/js/func.js'

export default {
  props: ['userId', 'lastVisit', 'photo'],
  computed: {
    online() {
      return online
    },
  },
}
</script>

<style scoped>
.min-photo {
  height: 48px;
  width: 48px;
}
</style>
