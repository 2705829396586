<template>
    <div class="user-menu-wrapper" v-click-outside="outside">
        <div class="user-menu">
            <b>
                <i v-if="section" class="user-link fa-solid fa-arrow-left pointer" @click="defaultTitle"></i>
                {{ title }}</b>
            <hr />
            <ul>
                <Menu v-if="!section" @section="SectionToggler" />
                <Settings v-if="section === 'settings'" />
            </ul>
        </div>
    </div>
</template>
<style >
.user-menu-wrapper {
    position: fixed;
    right: 0;
    padding-right: var(--bs-gutter-x, .75rem);
    min-width: 300px;
}

.user-menu {
    background: var(--link) none repeat scroll 0 0;
    color: var(--hover);
    border-radius: 6pt;
    font-size: 14pt;
    margin-bottom: 10px;
    list-style-type: none;
    padding: 10px;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);

}

ul {
    list-style-type: none;
    padding-left: 0;
}

.user-menu hr {
    margin-top: 5pt;
}

.user-link {
    color: var(--primary);
    fill: var(--primary);
    width: 100%;
}

.user-link:hover {
    color: var(--msg-bg);
    fill: var(--msg-bg);
}

.user-link:active {
    color: var(--hover);
    fill: var(--hover);
}

.user-link-active {
    color: var(--nav-link-active);
    fill: var(--nav-link-active);
}
</style>
<script>
import Menu from "@/components/UserMenu/Menu"
import Settings from "@/components/UserMenu/Settings"
export default {
    name: "UserMenu",
    components: {
        Menu, Settings
    },
    data() {
        return {
            section: '',
            title: '',

        }
    },
    methods: {
        outside(event) {
            if (!event.target.classList.contains('user-link')) {
                this.$emit("outside", event)
            }
        },
        SectionToggler(val) {
            if (val.section) {
                this.section = val.section
                this.title = val.title
                console.log(this.section);
            } else {
                this.defaultTitle()
            }

        },
        defaultTitle() {
            this.title = this.$store.state.user.firstName + ' ' + this.$store.state.user.lastName
            this.section = ''
        }
    },
    mounted() {
        this.defaultTitle()
    }
}
</script>