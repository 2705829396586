<template>
    <div v-cloak v-show="myId" class="base menu-wrapper">
        <div class="menu" v-if="!$store.state.mobile">
            <input type="text" id="user-search" class="form-control" placeholder="Поиск по имени" v-model="filter" />
            <ul>
                <div v-for="user in filteredUser" :key="user.userId" :data-user="user.userId" class="user-wrapper">
                    <li class="menu-item" :class="{ 'menu-item-active': $route.params.id == user.userId }"
                        v-if="user.userId !== this.$store.state.myId" :data-user="user.userId" @click="outside">
                        <router-link class="menu-link" :to="!$store.state.edit ? `/profile/id${user.userId}` : ''">
                            <div class=" user-name">
                                <div class="user-avatar">
                                    <AvatarMin :userId="user.userId" :lastVisit="user.lastVisit" :photo="user.photo128" />
                                </div>
                                <div>
                                    <div>{{ nameCut(user.firstName) }} {{ user.lastName }}</div>
                                    <div class="user-status">
                                        {{ statusCut(user.status) }}
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </div>
            </ul>
        </div>
    </div>
</template>
  
<script>
import AvatarMin from "@/components/AvatarMin.vue"
import { errorHandler } from "@/../public/js/func.js"
export default {
    data() {
        return {
            myId: 0,
            users: "",
            filter: "",
        }
    },
    name: "Friends",
    components: {
        AvatarMin,
    },
    props: ["slided"],
    methods: {
        errorHandler: errorHandler,
        outside(event) {
            this.$emit("outside", event)
        },
        nameCut(name) {
            if (name.length > 7) return name.substring(0, 1) + '.';
            return name;
        },
        statusCut(status) {
            if (status.length > 14) return status.substring(0, 11) + '...';
            return status;
        }
    },
    computed: {
        filteredUser() {
            return this.filter
                ? this.users.filter((user) =>
                    [`${user.firstName} ${user.lastName}`].some((v) =>
                        v.toLowerCase().includes(this.filter.toLowerCase())
                    )
                )
                : this.users
        },

    },
    mounted() {
        var img = new Image() //document.createElement('img');
        img.src = require("@/../public/img/404.jpg")
        this.axios
            .get("/users/")
            .then((res) => {
                const amountImg = res.data.length
                var loadedImg = 0
                res.data.forEach((usr) => {
                    let img = new Image() //document.createElement('img');
                    img.id = usr.id
                    img.src = usr.photo
                    img.onload =
                        img.onerror =
                        img.onabort =
                        () => {
                            ++loadedImg
                            if (loadedImg === amountImg) {
                                this.$store.state.loaded.avatars = true
                                this.emitter.emit("loaded", true)
                            }
                        }
                    img = null
                })
                this.users = res.data
            })
            .catch((res) => {
                console.log(this.$store.state.myId)
                console.log(res.response)
                this.errorHandler(res.response.status)
            })
        this.emitter.on("loaded", () => {
            if (!this.myId) this.myId = this.$store.state.user.userId
        })
    },
}
</script>
<style lang="scss" scoped>
.menu {}


ul {
    padding-left: 0;
    overflow: hidden;
    height: 20em;
    margin-bottom: 0;
}

ul:hover {
    overflow-y: scroll;
}

.user-name {
    display: flex;
}

.user-avatar {
    margin-right: 5pt;
}

.user-status {
    font-size: 1rem;
    color: var(--block-time)
}



.user>* {
    fill: var(--block-time) !important;
    color: var(--block-time) !important;
}

#user-search {
    position: sticky;
    top: 0;
    z-index: 199;
}

.user {
    margin: 3px 0;
}


.user:hover {
    background-color: var(--hover);
}

.this-page {
    background: var(--foreground);
}

.this-page>* {
    color: var(--background) !important;
    fill: var(--background) !important;
}
</style>
  